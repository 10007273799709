import React, { useEffect } from 'react';
import useToggle from '../../Hooks/useToggle';
import StickyMenu from '../../lib/StickyMenu';
import BackToTop from '../BackToTop';
import Footer from '../Home/Footer/Footer';
import Drawer from '../Mobile/Drawer';
import TermsDetails from './TermsDetails';
import TermsHeader from '../Home/Header';
import TermsHero from './TermsHero';

function Service() {
    useEffect(() => {
        StickyMenu();
    });
    const [drawer, drawerAction] = useToggle(false);
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <TermsHeader action={drawerAction.toggle} />
            <TermsHero />
            <TermsDetails />
            <Footer />
            <BackToTop />
        </>
    );
}

export default Service;
