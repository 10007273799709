import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../components/Home';
import PrivacyPolicy from '../components/PrivacyPolicy'
import Contact from '../components/Contact'
import Loader from '../components/Helper/Loader';
import ScrollToTop from '../components/Helper/ScrollToTop';
import './Routes.css'
import Terms from '../components/Terms';
import About from '../components/About';
import Blog from '../components/Blog';
import BSES from '../components/Sbes';
import Success from '../components/Constants/success'
// import BlogPage from '../components/Blog/BlogPage'
import SingleNews from '../components/Blog/SingleNews';
import Service from '../components/Service';
import Review from '../components/Review';
import Revamp from '../components/Revamp';
import Register from '../components/Auth/Register';
import Login from '../components/Auth/Login';
import {UserContextProvider} from "../components/Auth/UserContext";
import CreateBlog from '../components/Blog/CreateBlog';
import UpdateBlog from '../components/Blog/UpdateBlog'

const RoutesContainer = () => {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        window.scrollTo(0, 0);
    });
    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    });
    return (
        <>
            {loading && (
                <div className={`app-loader ${loading ? 'active' : ''}`}>
                    <Loader />
                </div>
            )}
            <div className={`app-visible ${loading === false ? 'active' : ''}`}>
                    <ScrollToTop> 
                    <UserContextProvider>
                        <Routes>
                            <Route exact path="/" element={<Blog/>} />
                            <Route exact path="/about" element={<About/>} />
                            <Route exact path="/services" element={<Service/>} />
                            <Route exact path="/privacy" element={<PrivacyPolicy/>} />
                            <Route exact path="/blog" element={<Blog/>} />
                            <Route exact path="/post/:id" element={<SingleNews />} />
                            <Route exact path="/blog-create" element={<CreateBlog/>} />
                            <Route exact path="/blog-update/:id" element={<UpdateBlog/>} />

                            {/* <Route exact path="/blog/exploring-role-incubation-nurturing-emerging-talent" element={<SingleNews/>} />  */}
                            <Route exact path="/terms" element={<Terms/>} />
                            <Route exact path="/academy" element={<BSES/>} />
                            <Route exact path="/post-register" element={<Register />} />
                            <Route exact path="/post-login" element={<Login />} />
                            <Route exact path="/cv-review" element={<Review />} />
                            <Route exact path="/cv-revamp" element={<Revamp />} />
                            <Route exact path="/support" element={<Contact/>} />
                            <Route exact path="/success" element={<Success/>} />
                        </Routes>
                        </UserContextProvider>
                    </ScrollToTop>
            </div>
        </>
    );
}

export default RoutesContainer;
