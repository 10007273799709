import React from 'react';

function HeroAbout() {
    return (
        <>
            <div className="app-about-top-title-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="app-about-top-title">
                                <h2 className="title">Your Global Access to Verified Talents</h2>
                                {/* <p>We offer you a panoply of cutting-edge options.</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="app-about-page-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="app-about-page-content">
                                <h3 className="title">
                                    Join The Revolutionary Talent Acquisition Platform for Employers! 🌟
                                </h3>
                                <p>
                                Blakskill Limited is a global HR technology company focused on talent identification, 
                                incubation, acquisition, and verification. We are a bridge between talents (professionals) and talent seekers (employers).
                                </p>
                                <p>
                                Our digital solution provides a platform that connects African talents with global job opportunities while offering streamlined
                                 recruitment solutions for organisations.
                                </p>
                                <p>As Blakskill provides global access to verified talents we are also positioned to meeting the talent acquisition needs of stakeholders in the global talent marketplace.</p>
                                {/* <a href="#">
                                    View all Members <i className="fal fa-arrow-right"></i>
                                </a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HeroAbout;
