import React from 'react';
import thumb from '../../assets/images/cv-review.jpg';

function DetailsService() {
    return (
        <>
            <section className="app-service-details-area pt-100 pb-100" style={{ backgroundColor: '#f3f3f3'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="service-details-sidebar mr-50">
                                <div className="service-category-widget">
                                    <ul>
                                        {/* <li>
                                            <i className="fal fa-file"></i>Registration and Subscription
                                        </li> */}
                                        <li>
                                            <i className="fal fa-upload"></i>CV Upload
                                        </li>
                                        <li>
                                            <i className="fal fa-user-friends"></i>Review by Experts
                                        </li>
                                        <li>
                                            <i className="fal fa-bookmark"></i>Feedback
                                        </li>
                                        <li>
                                            <i className="fal fa-globe"></i>Coaching and Support
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="service-details-content">
                                <div className="thumb">
                                    <img src={thumb} alt="CV Review" />
                                </div>
                                <div className="content">
                                    <h3 className="title">CV Review</h3>
                                    <p>
                                    Blakskill's CV review and revamp service is designed to help job seekers create professional-looking CVs that accurately reflect their skills and experience. By subscribing to the service, job seekers can upload their current CVs on the Blakskill website and make secure online payments for the review or revamp service.
                                    </p>
                                    <p>
                                    Our team of experienced professionals will carefully review each CV and provide detailed feedback on areas that need improvement, such as formatting, content, and structure. Additionally, we offer comprehensive CV revamping to enhance the overall presentation and impact of the CV. During the revamp process, we will collaborate with job seekers to identify their unique strengths and achievements, and tailor the CV accordingly to highlight these aspects effectively. We understand the importance of aligning the CV with specific job applications and industries, and our experts will provide guidance on how to optimise the CV for maximum impact.
                                    </p>
                                    <p>
                                    Blakskill's CV review service aims to address the challenges faced by job seekers in creating impactful CVs. We strive to ensure that each CV stands out from the competition, effectively showcases the candidate's qualifications, and increases their chances of securing their desired job opportunities.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default DetailsService;
