import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../assets/images/Blakskill.png';
import './Footer.css'

const Footer = ({ className }) => {
    return (
        <>
            <section className={`app-footer-area pb-30 ${className || ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="footer-about-widget">
                                <div className="logo">
                                    <Link to="#">
                                        <img src={logo} alt="" /> 
                                        {/* <span className="text-center" style={{ color: '#0c0c0c', fontSize: '24px', fontWeight: '500'}}>Blakskill</span> */}
                                    </Link>
                                </div>
                                <p>
                                The #1 platform for talent identification, incubation, acquisition, and verification.                                </p>
                                <Link to="#">
                                   
                                </Link>
                                <div className="social mt-30">
                                    <ul>
                                        <li>
                                            <a href="https://web.facebook.com/Blakskill/" target="blank">
                                                <i className="fab fa-facebook-f" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://twitter.com/Blakskill1" target="blank">
                                                <i className="fab fa-twitter" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/blakskill1/" target="blank">
                                                <i className="fab fa-instagram" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.linkedin.com/company/blakskill-limited/about/" target="blank">
                                                <i className="fab fa-linkedin-in" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6">
                            <div className="footer-navigation">
                                <h4 className="title">Company</h4>
                                <ul>
                                    <li>
                                        <Link to="/about">About Us</Link>
                                    </li>
                                    <li>
                                        <Link to="/services">Services</Link>
                                    </li>
                                    <li>
                                        <Link to="/">Blog</Link>
                                    </li>
                                    <li>
                                        <Link to="/support">Contact</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footer-navigation">
                                <h4 className="title">Support</h4>
                                <ul>
                                    <li>
                                        <Link to="/">Community</Link>
                                    </li>
                                    <li>
                                        <Link to="#">Faqs</Link>
                                    </li>
                                    <li>
                                        <Link to="/privacy">Privacy Policy</Link>
                                    </li>
                                    <li>
                                        <Link to="/">Terms</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footer-widget-info">
                                <h4 className="title">Get In Touch</h4>
                                <ul>
                                    <li>
                                       
                                        <a href="mailto:support@blakskill.com">   <i className="fal fa-envelope" /> support@blakskill.com</a> 
                                    
                                    </li>
                                    <li>
                                    
                                          <a href="tel:+2348138137633"> <i className="fal fa-phone" /> +(234) 915 9999 323</a> 
                                        
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <i className="fal fa-map-marker-alt" /> 5 Idowu Taylor Street, Victoria Island, Lagos.
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="footer-copyright d-flex align-items-center justify-content-between pt-35">
                            <div className="copyright-text">
                                    <p>Copyright © 2023 Blakskill. All rights reserved.</p>
                                </div>
                                {/* <div className="apps-download-btn">
                                    <ul>
                                        <li>
                                            <Link to="#" className="item-2">
                                                <i className="fab fa-apple" /> Download for iOS
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="item-2" to="#">
                                                <i className="fab fa-google-play" /> Download for
                                                Android
                                            </Link>
                                        </li>
                                    </ul>
                                </div> */}

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Footer;
