import React from 'react'
// import blogImg1 from '../../assets/images/blog/close-up-colleagues-work.jpg';
import {formatISO9075} from "date-fns";
import { Link } from 'react-router-dom';

const Blog = ({_id,title,summary,cover,content,createdAt,author}) => {
  return (
    <div className="col-lg-4">
    <div className="post-item-1">
        <img src={cover} style={{ width: '360px', height: '300px' }} alt="close-up-colleagues-work" />
        <div className="b-post-details">
            <div className="bp-meta">
            <Link to={`/post/${_id}`}>
                    <i className="fal fa-clock"></i>{formatISO9075(new Date(createdAt))}
                </Link>
            </div>
            <h3>
                <Link to={`/post/${_id}`}>
                {title}
                </Link>
            </h3>
            <Link to={`/post/${_id}`}>
                Read More<i className="fal fa-arrow-right"></i>
            </Link>
        </div>
    </div>
</div>
  )
}

export default Blog
