import React, { useState } from 'react'
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Footer from '../Home/Footer/Footer';
import Drawer from '../Mobile/Drawer';
import Header from '../Home/Header';
const API_BASE_URL = process.env.REACT_APP_API;

const Register = () => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [drawer, drawerAction] = useToggle(false);

const submitHandler = async(ev) => {
        ev.preventDefault();
        const response = await fetch(`${API_BASE_URL}/register`, {
          method: 'POST',
          body: JSON.stringify({username,password}),
          headers: {'Content-Type':'application/json'},
        });
        if (response.status === 200) {
          alert('registration successful');
        } else {
          alert('registration failed');
        }
      }

  return (
    <>
    <Drawer drawer={drawer} action={drawerAction.toggle} />
    <Header action={drawerAction.toggle} />
    <div className="app-page-title-area app-page-service-title-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="app-page-title-item">
                                <h3 className="title" style={{ color: '#0C0C0C'}}>
                                    Register
                                </h3>
                                {/* <span style={{ color: '#0C0C0C'}}>Blakskill</span> */}
                                <div className="thumb">
                                    {/* <img src={thumb} alt="" /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    <section className="contact-section">
        <div className="container">
            <div className="row">
            
                <div className="col-md-8">
                    <div className="contact-form">
                        <form onSubmit={submitHandler} className="row">
                            <div className="col-md-12">
                                <input type="text" name="username" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Username" required />
                                       
                            </div>
                            <div className="col-md-12">
                                <input
                                    type="password"
                                    name="password" value={password}
                                    placeholder="Password" required onChange={(e) => setPassword(e.target.value)}
                                />          
                            </div>
 
                            <div className="col-md-6">
                                <input type="submit" name="submit" value="Sign Up" />
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    </section>
    <Footer />
<BackToTop />
</>
  )
}

export default Register
