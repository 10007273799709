import React from 'react';

const DetailsPolicy = () => {
    return (
        <>
            <section className="app-service-details-area pt-100 pb-100" style={{ background: '#f2f2f7'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="service-details-content">
                            <h1 className='title'>Talent Resourcing Services Agreement</h1>
    <p className='pb-15 pt-15'>(the "Agreement") is made this …………day of …………………… 2023.</p>

    <h6 className='title'>BETWEEN:</h6>
    <p className='pb-15 pt-15'><strong>BLAKSKILL LIMITED (the "Agency")</strong>, a company organized and existing under the laws of the Federal Republic of Nigeria, with its head office located at 5 Idowu Taylor, Victoria Island Lagos [hereinafter referred to as "the Agency" which expression shall where the context so admits include its successors-in-title and assigns] of the other part.</p>

    <h6 className='title'>AND</h6>
    <p className='pb-15 pt-15'>(Insert Client’s Name), a company organised and existing under the laws of the Federal Republic of Nigeria with its registered office located at xxxxxxx [hereinafter referred to as "the Client" which expression shall where the context so admits include its successors-in-title and assigns] of the one part.</p>

    <p className='pb-15 pt-15'>The Agency and the Client are individually referred to as "Party" and collectively as the "Parties".</p>

    <h3 className='title pb-15'>WHEREAS:</h3>
    <ol>
        <li className='pb-15'>The Client wishes to engage the services of a reputable and competent Human Resources Company for the provision of talent resourcing/recruitment support services.</li>
        <li className='pb-15'>The Agency has represented and warranted to the Client that it is competent, qualified, and adequately equipped to provide such Talent Sourcing/Recruitment Support services as the Client may require at the instance or from time to time in accordance with the procedures and guidelines as may from time to time be determined by the Client.</li>
        <li className='pb-15'>Further to (ii) above, the Client has agreed to engage the services of the Agency under the terms and conditions hereinafter set forth.</li>
    </ol>

    <h3>IT IS HEREBY AGREED AS FOLLOWS:</h3>
    <h4 className="title pt-25 pb-25">2. COMMENCEMENT AND TERM</h4>
    <ul>
        <li className='pb-15'>2.1 This Agreement shall become effective on the "Effective Date" and shall remain in effect until terminated as per the terms set forth herein.</li>
        <li className='pb-15'>2.2 The initial duration of this Agreement shall commence on the Effective Date and shall remain in effect unless terminated earlier in accordance with the provisions of this Agreement.</li>
    </ul>

    <h4 className="title pt-25 pb-25">3. OBLIGATIONS OF THE PARTIES</h4>
    <ul>
        <li className='pb-15'>3.1 The Agency understands and agrees that services to be provided to the Client under and in pursuant to this agreement shall be so provided in such a way that the Client in its sole discretion shall determine the fit of the candidates provided.</li>
        <li className='pb-15'>3.2 The Agency agrees to provide services upon the written request of the Client and further agrees that the cost of such services shall not exceed the limitation of cost set forth by the Client’s request.</li>
        <li className='pb-15'>3.3 The Client shall provide the person’s specifications required and agree with timelines and deadlines for the assignment with the Agency.</li>
        <li className='pb-15'>3.4 The Client shall provide the job descriptions of the roles to be sourced for and other relevant information as may be required by the Agency to aid in the effective delivery of the talent sourcing/recruitment support services.</li>
    </ul>

    <h4 className="title pt-25 pb-25">4. ENGAGEMENT GUIDELINES</h4>
    <ul>
        <li className='pb-15'>4.1 The Agency shall, upon request by the Client, source for suitable and qualified candidates for the roles advised by the Client. The Agency agrees to use suitable assessment and screening techniques to provide a shortlist of 2-3 candidates for each advised role within ten business days or as agreed.</li>
        <li className='pb-15'>4.2 Based on the final decision of the Client, the successful candidate(s) identified for the position(s) will be duly communicated to the Agency.</li>
        <li className='pb-15'>4.3 The Client will ensure a turn-around time of not more than one week between when the Agency sends a shortlist of successful candidates and when the Client communicates the date and time for meeting with the candidates for further interviews. In the event that the Client is unable to communicate an interview date as a result of the unavailability of the interviewer within one week, the Agency shall be notified of this and a date will be communicated not later than two (2) weeks from when the shortlist was sent.</li>
        <li className='pb-15'>4.4 In instances where the short-listed candidates do not meet the Client’s specified requirements, the Agency will provide the Client with more suitable candidates.</li>
        <li className='pb-15'>4.5 Where a candidate referred by the Agency and engaged by the Client resigns his/her employment within the first one (1) month of employment for a reason, not due to unavailability of work tools or unmet promises by the Client leading to dissatisfaction/frustration of the employee or when the Client is compelled to disengage the hired candidate due to poor performance or unmet expectations within the first one (1) month of employment (with documented facts to support this claim), the Agency shall replace the candidate at no cost to the Client. The due reason for such resignation shall first be established with documented evidences.</li>
        <li className='pb-15'>4.6 Both parties acknowledge that the project timeline provided by the Agency is an estimate and is subject to change based on various factors, including candidate availability, market conditions, and unforeseen circumstances. Any proposed changes to the project timeline shall be promptly communicated to the Client in writing, and both parties shall work together in good faith to agree upon revised deadlines.</li>
        <li className='pb-15'>4.7 The Client agrees to provide all necessary information, feedback, and resources in a timely manner to facilitate the adherence to the project timeline. Delays caused by the Client's failure to provide required information, participate in interviews, or make timely decisions shall not be the responsibility of the Agency.</li>
        <li className='pb-15'>4.8 In the event that either party anticipates a potential delay in meeting a project milestone or deadline, that party shall promptly notify the other party in writing, providing reasons for the delay and a proposed revised timeline. Both parties shall collaborate to assess the impact of the delay and to adjust the overall project timeline accordingly.</li>
        <li className='pb-15'>4.9 The timely execution of the project timeline is essential to ensure the successful completion of the recruitment process within the desired timeframe. Both parties recognize that adherence to the project timeline contributes to managing the Client's expectations and delivering a recruitment process that is efficient and effective.</li>
    </ul>

    <h4 class="title pt-25 pb-25">5. CONSIDERATION AND PAYMENT TERMS</h4>
    <ul>
        <li className='pb-15'>5.1 Once an offer of employment has been made by the Client and accepted by the successful candidate for the role, Blakskill Limited shall be paid 10% of the gross annual remuneration of the successful candidate as a professional services fee.</li>
        <li className='pb-15'>5.2 The Professional Service Fee shall cover all costs incurred by the Agency in the course of the provision of talent sourcing services.</li>
        <li className='pb-15'>5.3 The Background check services on successful candidates will be conducted by the Agency.</li>
        <li className='pb-15'>5.4 Specifically, such costs would include interstate transportation if the trip is made specifically at the Client’s behest.</li>
        <li className='pb-15'>5.5 In the event that the Client requires such a specialized assessment, the Agency will provide its expertise at no additional administrative costs.</li>
    </ul>

    <h4 class="title pt-25 pb-25">6. CHANGE REQUEST AND AUTHORIZATION FOR SCOPE MODIFICATIONS</h4>
    <ul>
        <li className='pb-15'>6.1 During the engagement, either party may request changes to the scope or nature of the services.</li>
        <li className='pb-15'>6.2 The Agency shall not commence work on any change until agreed in writing.</li>
        <li className='pb-15'>6.3 Within five days of receipt of a proposed change request, the Client should either indicate its acceptance or advise the Agency not to perform the changes.</li>
    </ul>

    <h4 class="title pt-25 pb-25">7. INTELLECTUAL PROPERTY</h4>
    <ul>
        <li className='pb-15'>7.1 Any materials provided by both parties remain the exclusive property of the parties.</li>
        <li className='pb-15'>7.2 Candidate profiles and related data are the intellectual property of the Agency.</li>
        <li className='pb-15'>7.3 Each party agrees to treat all materials as confidential.</li>
        <li className='pb-15'>7.4 Each party shall not create derivative works without prior written consent.</li>
        <li className='pb-15'>7.5 Upon termination, the Client shall promptly return all materials.</li>
        <li className='pb-15'>7.6 Both parties shall indemnify against unauthorized use of intellectual property.</li>
        <li className='pb-15'>7.7 Nothing in this agreement grants additional rights or licenses.</li>
        <li className='pb-15'>7.8 Public domain intellectual property remains in the public domain.</li>
        <li className='pb-15'>7.9 This intellectual property clause survives the termination of the agreement.</li>
    </ul>

    <h4 className="title pt-25 pb-25">8. INDEPENDENT CONTRACTOR</h4>
<ul>
    <li className="pb-15">8.1 The status of the Agency is that of an independent contractor and not of an agent or employee of the Client and, as such, Agency shall not have the right or power to enter into any contracts, agreements, or any other commitments on behalf of Client.</li>
</ul>

<h4 className="title pt-25 pb-25">9. ASSIGNMENT</h4>
<ul>
    <li className="pb-15">9.1 The rights and obligations of the Agency under this agreement are personal to the Agency and may not be assigned or transferred to any other person, firm, corporation, or other entity without the prior, express, and written consent.</li>
    <li className="pb-15">9.2 The Agency reserves the right to source for and engage the candidates in any way it deems fit to ensure they meet the Client’s specifications as long as it does not violate the Laws of the Federal Republic of Nigeria. The Agency reserves all the rights to provide candidates with all necessary information to enhance their chances of securing the job.</li>
</ul>

<h4 className="title pt-25 pb-25">10. EXCLUSIVITY</h4>
<ul>
    <li className="pb-15">10.1 The Client agrees to engage the services of The Agency for the recruitment of employees on an exclusive basis for every job brief passed to The Agency. The Client shall not directly engage, solicit, or contract with any other third-party entity or agency for the recruitment of candidates for the specified positions.</li>
    <li className="pb-15">10.2 Upon the inability of The Agency to provide suitable candidates within the agreed timeline, the Client is then relieved from the exclusivity clause provided that such is not due to the client’s inability to provide required information and meet hitherto agreed obligations. The exclusivity clause is applicable to all positions that have been contracted to The Agency.</li>
    <li className="pb-15">10.3 In the event that the Client does not prefer the exclusivity option for any recruitment exercise, the Client will make a 60% upfront payment of the Professional Service fee to The Agency.</li>
    <li className="pb-15">10.4 All candidates introduced to the Client shall remain the Agency’s referred candidates and the Client shall not engage the candidates anytime during the duration of this agreement or for a period of 24 months after without recourse to the content of this agreement.</li>
</ul>

<h4 className="title pt-25 pb-25">11. CONFIDENTIALITY AND NON-DISCLOSURE OBLIGATIONS OF CLIENT’S INFORMATION</h4>
<ul>
    <li className="pb-15">11.1 The Client acknowledges and agrees that the candidate information provided by the Agency is of a confidential and sensitive nature. The Client shall maintain the confidentiality of such candidate information and shall not disclose, reproduce, or use such information for any purpose other than the recruitment process outlined in this Agreement.</li>
    <li className="pb-15">11.2 The Client shall take all necessary precautions to prevent unauthorized access to candidate information, including but not limited to implementing and maintaining appropriate technical and organizational security measures.</li>
    <li className="pb-15">11.3 The Client shall ensure that its employees, agents, or any third parties engaged in the recruitment process are made aware of and adhere to the confidentiality obligations set forth in this clause.</li>
    <li className="pb-15">11.4 The Client shall ensure compliance with all applicable data protection and privacy laws and regulations when handling candidate information. This includes obtaining necessary consents, providing candidates with privacy notices, and adhering to data retention periods.</li>
</ul>

<h4 className="title pt-25 pb-25">12. DATA HANDLING AND RETENTION</h4>
<ul>
    <li className="pb-15">12.1 Any personal information provided by the Agency shall be used solely for the purpose of evaluating candidates for the relevant job positions and conducting the recruitment process in line with the scope in this agreement.</li>
    <li className="pb-15">12.2 Once the recruitment process is completed or terminated, the Client shall promptly return or securely dispose of all unsuccessful candidate information in compliance with applicable data protection laws and regulations.</li>
    <li className="pb-15">12.3 The Client acknowledges and agrees that the security and confidentiality of candidate data are of paramount importance in the context of the recruitment process. The Client shall implement and maintain appropriate and reasonable security measures to ensure the protection of candidate data provided by the Agency. These security measures shall be designed to prevent unauthorized access, disclosure, alteration, or destruction of candidate data.</li>
</ul>

<h4 class="title pt-25 pb-25">13. PERSONAL DATA PROCESSING AND PROTECTION</h4>
<ul>
    <li className='pb-15'>13.1 Words not defined in this Agreement with respect to this clause 12 are as defined in the Nigeria Data Protection Act</li>
    <li className='pb-15'>13.2 The Personal Data referred to in this section are those received by the Parties under or in connection with this Agreement</li>
    <li className='pb-15'>13.3 The Parties shall comply with the data protection laws, regulations and implementation framework (including but not limited to the Nigeria Data Protection Act 2023 (NDPA) in force in the Federal Republic of Nigeria in the processing of Personal Data obtained in the connection with and for the Services under this Agreement.</li>
    <li className='pb-15'>13.4 In fulfilment of its obligations under the NDPA, Parties will have such security measures or systems in place to ensure full compliance with the NDPA and all other applicable laws; such measures shall include but not limited to protecting systems from hackers, setting up firewalls, storing data securely with access to specific authorized individuals, employing data encryption technologies, developing organizational policy for handling personal data (and other sensitive or confidential data), protection of emailing systems and continuous capacity building for staff.</li>
    <li className='pb-15'>13.5 The Parties shall process Personal Data solely for the purpose for which the data is collected.</li>
    <li className='pb-15'>13.6 Where a Party utilizes third party processors to perform its obligations under this Agreement, that Party shall ensure that the third-party processor is under a data protection obligation as those contained in this Agreement</li>
    <li className='pb-15'>13.7 The Parties shall ensure that Personal Data obtained from the other Party pursuant to this Agreement is not transmitted, processed or stored in another geographical location outside Nigeria without the prior written consent of the Data Subject</li>
    <li className='pb-15'>13.8 The Parties shall limit access to Personal Data to its employees who have a strict need to know same on a strictly need-to-know basis for the purpose of the performance, management and monitoring of this Agreement.</li>
    <li className='pb-15'>13.9 The Parties shall ensure that it adopts and implements adequate physical and technical measures to guarantee the confidentiality, secure processing, and storage of Personal Data obtained and processed pursuant to its obligations under this Agreement.</li>

    <li className='pb-15'>13.10 The Parties undertake to adopt appropriate technical and organizational security measures having regard to the risks inherent in processing Personal Data in order to:
        <ul>
            <li className='pb-15'>13.10.1 Prevent unauthorized person from having access to computer systems processing Personal Data</li>
            <li className='pb-15'>13.10.2 Prevent unauthorized viewing, copying, distribution, alteration or removal of storage media.</li>
            <li className='pb-15'>13.10.3 Prevent unauthorized data input as well as unauthorized disclosure, alteration or erasure of stored Personal Data</li>
            <li className='pb-15'>13.10.4 Prevent unauthorized use of data-processing systems by means of data transmission facilities.</li>
            <li className='pb-15'>13.10.5 Ensure that authorized users of a data-processing system can access only the Personal and Sensitive Data to which their access right refers</li>
            <li className='pb-15'>13.10.6 Record which Personal Data have been communicated, when and to whom and distributed only through approved means</li>
            <li className='pb-15'>13.10.7 Ensure that Personal Data being processed on behalf of a Party can be processed only in the manner prescribed by the Party</li>
            <li className='pb-15'>13.10.8 Ensure that during communication and transmission of Personal Data, the data cannot be read, copied, or erased without authorisation.</li>
        </ul>
    </li>
  
    <li className='pb-15'>13.11 Upon demand by Party, or upon termination of this Agreement, a Party shall return to the other Party or destroy the Personal Data received pursuant to this Agreement.</li>
    <li className='pb-15'>13.12 Each Party shall design its organizational structure in such a way that meets the data protection requirements of the NDPA or the applicable data protection law or regulation.</li>
    <li className='pb-15'>13.13 Each Party shall provide data protection trainings and sensitization to its employees and agents</li>
</ul>

<h4 class="title pt-25 pb-25">14. NON-CIRCUMVENTION</h4>
<ul>
    <li className='pb-15'>14.1 During the term of this agreement and for a period of 24 months following its termination, the Client agrees not to directly or indirectly engage, solicit, negotiate, hire, contract, or enter into any arrangement with any candidates introduced by the Agency, whether such introduction was made directly or indirectly, or through any intermediary, for the purpose of circumventing or avoiding the payment of consideration to the Agency as per the terms of this agreement.</li>
    <li className='pb-15'>14.2 The Client shall not initiate contact with or communicate in any form with candidates introduced by the Agency, whether successful or unsuccessful, with the intent to engage their services for any employment, consulting, contracting, or similar arrangement without obtaining explicit written consent from the Agency.</li>
    <li className='pb-15'>14.3 In the event that the Client wishes to engage a candidate introduced by the Agency for a position not covered by the initial engagement under this agreement, the Client shall first notify the Agency in writing and seek express written consent for such engagement. If consent is granted, the parties shall mutually agree upon the terms of compensation payable to the Agency for the new engagement.</li>
    <li className='pb-15'>14.4 In the event that the Client breaches this non-circumvention clause by engaging, hiring, or contracting with any candidate introduced by the Agency without obtaining proper consent, the Client shall pay the Agency a default fee equal to 15% of the total compensation package (including base salary, bonuses, benefits, and other forms of compensation) offered to the candidate, within 10 business days of the commencement of the engagement.</li>
    <li className='pb-15'>14.5 This default fee is intended to compensate the Agency for the costs, efforts, and resources expended in identifying and introducing the candidate to the Client, as well as for the opportunity cost of the lost engagement.</li>
    <li className='pb-15'>14.6 This non-circumvention clause shall remain in effect during the term of this agreement and for 24 months after its termination, regardless of the reasons for termination. The obligations contained herein shall survive the termination of this agreement.</li>
</ul>

<h4 class="title pt-25 pb-25">15. REPRESENTATIONS AND WARRANTIES</h4>
<ul>
    <li className='pb-15'>15.1 Each Party hereto represents and warrants that:</li>
    <ul>
    <li className='pb-15'>15.1.1 It has corporate power (including, without limitation, under its memorandum and articles of association) to enter into and perform its obligations under this Agreement;</li>
    <li className='pb-15'>15.1.2	It has the legal authority necessary to enter into this Agreement, perform its obligations under it and enforce this Agreement;</li>
    <li className='pb-15'>15.1.3	It has the requisite facilities, technology and personnel to perform its obligations under this Agreement; and;</li>
    <li className='pb-15'>15.1.4	It has not performed and shall not perform any act which might prevent the other Party from enforcing any of the terms and conditions of the present Agreement or which would limit or restrict the other Party in such enforcement. </li>
    <li className='pb-15'>15.1.5	All information provided in relation to this Agreement are true, accurate and complete in every respect and are not misleading.</li>
    </ul>
    <li className='pb-15'>15.2 The Client represents and warrants that The Agency and its Consultants have the right to perform the services required under and pursuant to this agreement without violation of obligations to others, and that Agency and its Consultants have the right to disclose to the Client all information transmitted to the Client in the performance of services under and pursuant to this agreement.</li>

</ul>

<h4 class="title pt-25 pb-25">16. TERMINATION</h4>
<ul>
    <li className='pb-15'>16.1 This Agreement may be terminated by –</li>
    <ul>
        <li className='pb-15'>16.1.1 the mutual consent of the Parties; or</li>
        <li className='pb-15'>16.1.2 either Party, if the other Party fails to remedy a Material Breach of this Agreement despite repeated demands to remedy same, upon giving 30 (thirty) days' written notice to the other Party of its intention to terminate for the reason stated in this Clause; and for the purposes of this provision, "Material Breach" means –</li>
        <li className='pb-15'>16.1.3 in the case of the Agency, failure to fulfil its obligations in Clause 2;</li>
        <li className='pb-15'>16.1.4 in the case of the Client, failure to fulfil any of its financial commitment to the Agency under this Agreement; and</li>
        <li className='pb-15'>16.1.5 either Party, if the other Party goes into liquidation (otherwise than for the purpose only of amalgamation or reconstruction) or has a receiver appointed or makes any composition or arrangement with its creditors.</li>
        <li className='pb-15'>16.1.6 either party, if the other party becomes insolvent, enters bankruptcy, or ceases to conduct its business operations in the normal course.</li>
        <li className='pb-15'>16.1.7 if there is a change in the control or management of The Agency that is unacceptable to the Client; or if the Agency ceases to function as a going concern, or to conduct its operations in the normal course of business.</li>
    </ul>
    <li className='pb-15'>16.2 Termination of this Agreement shall not relieve either party from any obligations that, by their nature, are intended to survive termination, including but not limited to confidentiality, intellectual property, and payment obligations.</li>
    <li className='pb-15'>16.3 Upon termination of this Agreement for any reason:</li>
    <ul>
        <li className='pb-15'>16.3.1 The parties shall cease to perform their obligations under this Agreement, except as required to wind down and conclude any ongoing activities.</li>
    </ul>
    <li className='pb-15'>16.4 The Client shall promptly pay any outstanding fees or expenses owed to the Agency up to the effective date of termination.</li>
    <li className='pb-15'>16.5 Upon termination of this agreement, the Agency will return to The Client all written information, drawings, models, and other materials or files supplied to the Agency or created by the Agency at the expense of the Client.</li>
    <li className='pb-15'>16.6 Any rights, obligations, or liabilities that, by their nature, should survive termination shall remain in effect beyond the termination date.</li>
</ul>

<h4 className="title pt-25 pb-25">17. FORCE MAJEURE</h4>
<ul>
    <li className='pb-15'>17.1 Neither Party shall be responsible for any failure to fulfil any term or condition of the Agreement if and to the extent that fulfilment has been delayed or temporarily prevented by a force majeure occurrence, as hereunder defined, and which is unforeseeable, beyond the control, without the fault or negligence of the Party affected (Affected Party) and prevents the Affected Party from fulfilling its obligations under this Agreement.</li>
    <li className='pb-15'>17.2 For the purposes of this Agreement, force majeure relates to the occurrence of events such as:</li>
    <ul>
        <li className='pb-15'>17.2.1 Riot, war, acts of terrorism, civil war, rebellion, revolution, pandemics, epidemics, plagues, insurrection of military or usurped power;</li>
        <li className='pb-15'>17.2.2 Earthquake, flood, and/or other natural physical disaster, but excluding weather conditions regardless of severity;</li>
        <li className='pb-15'>17.2.3 Strikes at a national level or industrial disputes at a national level, or strikes or industrial disputes by workers not employed by the Affected Party and which affect a substantial portion of the provision of the services under this Agreement;</li>
        <li className='pb-15'>17.2.4 Acts or orders of governments or governmental bodies (including legislative bodies, local or port authorities), changes to any general or local Statute, Ordinance, Decree or other Law or any regulation or bye-law of any local or other duly constituted authority or the introduction of any such Statute, Ordinance, Decree, Law, regulation or bye-law;</li>
        <li className='pb-15'>17.2.5 Regulatory authority’s intervention negating the intention and terms of this Agreement.</li>
        <li className='pb-15'>17.2.6 Disease outbreak, epidemics or pandemics</li>
        <li className='pb-15'>17.2.7 Breakdown of national and off-grid power infrastructure, breakdown of network infrastructure, cyber-attacks etc</li>
    </ul>
    <li className='pb-15'>17.3 In the event of a force majeure occurrence, the Affected Party shall promptly notify the other Party in writing, giving the full particulars thereof and shall use all reasonable endeavors to remedy the situation immediately.</li>
    <li className='pb-15'>17.4 Following notification of a force majeure occurrence, the Parties shall meet without delay with a view to agreeing a mutually acceptable course of action to minimize any effects of such occurrence.</li>
    <li className='pb-15'>17.5 If the force majeure occurrence fails to abate after a period of sixty (60) days, either Party may terminate this Agreement.</li>
</ul>

<h4 className="title pt-25 pb-25">18. LIMITATION OF LIABILITY</h4>
<ul>
    <li className='pb-15'>18.1 The Client acknowledges and agrees that the outcomes of the recruitment process are influenced by a myriad of factors, many of which are external to the control of the Agency.</li>
    <li className='pb-15'>18.2 The Agency shall not be held liable for any losses, damages, expenses, claims, or liabilities incurred by the Client, whether direct or indirect, arising from or related to the recruitment process, including but not limited to candidate selection, performance, suitability, or any actions or decisions taken by the Client based on the information, recommendations, or services provided by the Agency.</li>
    <li className='pb-15'>18.3 The Client further acknowledges that the success of the recruitment process is contingent upon a variety of variables, including market conditions, candidate availability, industry trends, economic factors, and the specific requirements and preferences of the Client. The Agency shall not be responsible for the Client's expectations, assumptions, or actions that may lead to unfavorable outcomes, including but not limited to a candidate's performance, retention, or suitability for the position.</li>
    <li className='pb-15'>18.4 The Client assumes full responsibility for its decisions, actions, and expectations related to the recruitment process and agrees to hold the Agency harmless against any claims, demands, costs, liabilities, or losses arising from the Client's own actions, decisions, or expectations.</li>
    <li className='pb-15'>18.5 The Agency's liability is limited to the scope of its obligations as outlined in this agreement. The Client agrees that the Agency's liability for any and all claims, whether arising in contract, tort, or otherwise, shall not exceed the total fees paid by the Client to the Agency under this agreement.</li>
    <li className='pb-15'>18.6 This limitation of liability clause shall survive the termination or expiration of this agreement and shall apply to any claims or actions brought by the Client or any third parties against the Agency arising from or in connection with the recruitment process.</li>
</ul>

<h4 className="title pt-25 pb-25">19. INDEMNIFICATION</h4>
<ul>
    <li className='pb-15'>The Client undertakes, to indemnify, defend and hold harmless, jointly and severally, the Agency, its subsidiaries, affiliates, officers, directors, employees, agents, successors from and against all losses due to data security breach, the unauthorized use of shared personal information, any loss of funds; fraud, damages, actions, interest, awards, demands, liabilities, actions, judgment, penalties, fines, costs or expenses of whatever kind, including reasonable attorneys’ fees, damages and proceedings that may be made by any third party or incurred by the Agency arising as a consequence of negligence and unlawful acts or omissions on the part of the Client or its agents/subcontractors as well as the failure of the Client to comply with any of the provisions in this Agreement.</li>
</ul>


                            </div>
                        </div>
                    </div>
                    
                </div>
            </section>
        </>
    );
}

export default DetailsPolicy;
