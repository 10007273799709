import React from 'react';

function ServicesAbout() {
    return (
        <>
            <section className="app-services-2-area pt-90 pb-55" id="service">
                <div className="container">
                    <div className="row align-items-end">
                        <div className="col-lg-6 col-md-8">
                            <div className="app-section-title">
                                <h3 className="app-title">We’re driven by our values</h3>
                                <p>At Blakskill, we are guided by our core values, which form the foundation of our operations and shape our interactions with stakeholders. These values are: </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="app-single-service-2 app-single-service-about mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="icon">
                                    <i className="fal fa-tv"></i>
                                </div>
                                <h4 className="title">Innovation</h4>
                                {/* <p>Our innovation-driven culture advances talent acquisition using cutting-edge technology and forward-thinking approaches.</p> */}
                                <p>We foster a culture of continuous innovation, leveraging cutting-edge technology and forward-thinking approaches to drive advancements
                                     in the talent acquisition space.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="app-single-service-2 app-single-service-about item-2 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <div className="icon">
                                    <i className="fal fa-code"></i>
                                </div>
                                <h4 className="title">Solutions-Driven</h4>
                                {/* <p>We provide top-notch digital solutions for global talent marketplace.</p> */}
                                <p>We are committed to providing robust digital solutions that address the evolving 
                                    needs of our stakeholders in the global talent marketplace. We strive to exceed expectations and deliver exceptional results.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="app-single-service-2 app-single-service-about item-3 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                            >
                                <div className="icon">
                                    <i className="fal fa-user-friends"></i>
                                </div>
                                <h4 className="title">Teamwork</h4>
                                {/* <p>Teamwork and inclusion drive our success, enabling each member to thrive with their unique skills and perspectives.</p> */}
                                <p>Collaboration and teamwork are at the heart of our success. We believe in fostering a supportive and inclusive environment where
                                     every member of our team can thrive and contribute their unique skills and perspectives.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="app-single-service-2 app-single-service-about item-4 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="icon">
                                    <i className="fal fa-mobile"></i>
                                </div>
                                <h4 className="title">Agility</h4>
                                {/* <p>Our talent acquisition thrives on agile methodologies, adapting swiftly to stakeholder needs, and leading industry trends.</p> */}
                                <p>In the fast-paced world of talent acquisition, agility is key. We embrace change and adapt swiftly to the evolving needs of 
                                    our stakeholders, ensuring that we remain at the forefront of industry trends and best practices.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="app-single-service-2 app-single-service-about item-5 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <div className="icon">
                                    <i className="fal fa-retweet"></i>
                                </div>
                                <h4 className="title">Reliability</h4>
                                {/* <p>Our commitment is to trust, reliability, and professionalism in all interactions.</p> */}
                                <p>Trust and reliability are paramount in our interactions with jobseekers, employers, and partners. 
                                    We are committed to maintaining the highest standards of integrity, transparency, and professionalism in all aspects of our operations.</p>
                            </div>
                        </div>
                        {/* <div className="col-lg-4 col-md-6">
                            <div
                                className="app-single-service-2 app-single-service-about item-6 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                            >
                                <div className="icon">
                                    <i className="fal fa-bell"></i>
                                </div>
                                <h4 className="title">Notifications Settings</h4>
                                <p>He lost his bottle loo don't get shirty with me ruddy.</p>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    );
}

export default ServicesAbout;
