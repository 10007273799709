import React from "react";

const DetailsPolicy = () => {
  return (
    <>
      <section
        className="app-service-details-area pt-100 pb-100"
        style={{ background: "#f2f2f7" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="service-details-content">
                <h3 className="title">Introduction</h3>
                <div className="content">
                  <span>
                    Blakskill’s (“we” or “us”) mission is to provide a digital 
                    solution that meets the talent sourcing, development, access, visibility and needs of stakeholders in global talent marketplace. Central to this
                    mission is our commitment to be transparent about the data
                    we collect, how it is used and with whom it is shared with.
                  </span>
                  <span>
                    Registered users share their professional identities,
                    documentation, certificates, identification with us and this
                    information is viewable to categories of other users. This
                    Privacy Policy applies when you use our Services (described
                    below). We offer our users choices about the data we
                    collect about the data we collect, use and share.
                  </span>
                </div>

                <h3 className="title">Cookies</h3>
                <div className="content">
                  <span>
                    A “cookie” is a small piece of information stored by a Web
                    server on a Web browser so it can be later read back from
                    that browser. Cookies are useful for enabling the browser to
                    remember information specific to a given The Site may use
                    temporary cookies to help you access some of the special
                    functions within the database driven areas of our platform.
                    For example, We may store environmental variables, such as
                    browser type, operating system, speed of the central
                    processing unit (CPU), referring or exit web pages, click
                    patterns and the internet protocol (IP) address of Your
                    computer.Once you leave Our Site, these cookies expire.
                    Cookies deployed by us do not collect Your Personal
                    Information.
                  </span>
                </div>
                <h3 className="title">Changes</h3>
                <div className="content">
                  <span>
                    We can modify this Privacy Policy, and if we make material
                    changes to it, we will provide notice to provide you the
                    opportunity to review the changes before they become
                    effective. If you object to any changes, you may close your
                    account.
                  </span>
                  <span>
                    You acknowledge that your continued use of our Services
                    after we publish or send a notice about our changes to this
                    Privacy Policy means that the collection, use and sharing of
                    your personal data is subject to the updated Privacy Policy,
                    as of its effective date.
                  </span>
                </div>

                <h3 className="title">Data we collect</h3>
                <div className="content">
                  <h5>Data You Provide to us</h5>
                  <span>
                    Registration: To create an account you need to provide data
                    including your name, email address, home address, mobile
                    number and password. Were you seek to purchase a service,
                    you will need to provide payment (e.g. credit card) and
                    billing information.
                  </span>
                  <span>
                    Verification Documentation: Talents on the Blakskill Platform consent to the verification of their documents provided 
                    including; educational and professional certificates, references, previous employers and other required
                    documentation from time to time. Talent seekers and trainers
                    are also required to provide documentation to be verified by Blakskill
                    including but not limited to corporate documentation,
                    certification, etc.,
                  </span>
                  <span>
                    Profile: You have choices about the information on your
                    profile, such as your education, work experience, skills,
                    photo and endorsements. Profile information helps you to get
                    more from our Services, including helping recruiters and
                    business opportunities find you. It’s your choice whether to
                    include sensitive documentation  on your profile and to make
                    that sensitive information public.
                  </span>
                  <span>
                    Posting and Uploading: We collect personal data from you
                    when you provide, post or upload it to our Services, such as
                    when you fill out a form, (e.g., with demographic data or
                    salary), respond to a survey, or submit a resume or fill out
                    a job application on our Services.{" "}
                  </span>

                  <h5>Data from others</h5>
                  <span>
                    Partners: We receive personal data about you when you use
                    the services of our customers and partners, such as
                    employers or prospective employers and applicant tracking
                    systems providing us job application data.
                  </span>
                  <span>
                    Related Companies and Other Services: We receive data about
                    you when you use some of the other services provided by us
                    or our affiliates.
                  </span>
                  <span>
                    Service Use: We log usage data when you visit or otherwise
                    use our Services, including our sites, app and platform
                    technology, such as when you view or click on content (e.g.,
                    learning video) or ads (on or off our sites and apps),
                    perform a search, install or update one of our mobile apps,
                    share articles or apply for jobs. We use log-ins, cookies,
                    device information  and internet protocol (“IP”) addresses
                    to identify you and log your use.
                  </span>
                  <span>
                    We also collect information that does not reveal Your
                    specific identity, such as, browser information, information
                    collected through Cookies (defined below), pixel tags and
                    other technologies, demographic information, etc.
                  </span>
                  <span>
                    Referees, Guarantors, School, Work Place and professional
                    Organization and Third Party Verifications: In the process
                    of verifying the documentation and information you provide
                    and to ensure that talents are employment ready or that
                    talent trainers and seekers are reputable, we receive
                    information from your previous place of employment, schools,
                    professional organization, the regulatory authorities, etc.
                  </span>
                  <span>
                    Sites and Services of Others: We receive information about
                    your visits and interaction with services provided by others
                    when you log-in with Blakskill or visit others’ services
                    that include some of our plugins or our ads, cookies or
                    similar technologies.
                  </span>
                </div>

                <h3 className="title">How we use your data</h3>
                <div className="content">
                  <h5>
                    How we use your personal data will depend on which Services
                    you use, how you use those Services and the choices you make
                    in your settings.
                  </h5>
                  <span>
                    Talents: We use the data provided to verify you as a ready
                    for employment talent and to connect you with talent seekers
                    and trainers.
                  </span>
                  <span>
                    Talent Seeker: we use the data provided to ensure you are a
                    suitable employer to market to our talents and to match
                    you to talents.
                  </span>
                  <span>
                    Talent trainers: we use the data provided to ensure that you
                    possess the adequate qualification, certifications and
                    license to provide the training and to match you to talents.
                  </span>
                  <span>
                    We also use the information provided for the following;
                    marketing, research and development, surveys, customer
                    support.
                  </span>
                </div>

                <h3 className="title">How we share your information</h3>
                <div className="content">
                  <span>
                    Your profile is visible to other users of our platform to
                    give effect to our Services. We also share your information
                    with 3 rd parties for the purpose of recruitment, training
                    and verification.
                  </span>
    
                  <span>
                  Legal disclosures: It is possible that we will need to disclose information
                    about you when required by law, subpoena, or other legal
                    process or if we have a good faith belief that disclosure is
                    reasonably necessary to (1) investigate, prevent or take
                    action regarding suspected or actual illegal activities or
                    to assist government enforcement agencies; (2) enforce our
                    agreements with you; (3) investigate and defend ourselves
                    against any third- party claims or allegations; (4) protect
                    the security or integrity of our Services (such as by
                    sharing with companies facing similar threats); or (5)
                    exercise or protect the rights and safety of Blakskill, our
                    Members, personnel or others. We attempt to notify Members
                    about legal demands for their personal data when appropriate
                    in our judgment, unless prohibited by law or court order or
                    when the request is an emergency. We may dispute such
                    demands when we believe, in our discretion, that the
                    requests are overbroad, vague or lack proper authority, but
                    we do not promise to challenge every demand.
                  </span>
                  <span>
                    Change in control or sale: We can also share your personal
                    data as part of a sale, merger or change in control, or in
                    preparation for any of these events. Any other entity which
                    buys us or part of our business will have the right to
                    continue to use your data, but only in the manner set out in
                    this Privacy Policy unless you agree otherwise.
                  </span>
                </div>

                <h3 className="title">Security</h3>
                <div className="content">
                  <span>
                    We work hard to protect Your Personal Information from
                    unauthorized access to or unauthorized alteration,
                    disclosure or destruction. Pursuant to the same, we: (a)
                    Encrypt our services using secure server software, which is
                    the industry standard and among the best software available
                    today for secure transactions (b) we offer the use of a
                    secure server. However, please bear in mind that we cannot
                    guarantee that the internet in itself is 100% secure and so
                    we cannot take responsibility for the illegal acts of
                    hackers as long as we have taken the necessary precautions.
                    Although we will do our best to protect your personal
                    information, transmission of personal information to and
                    from our services is at your own risk. You should only
                    access our site or services through and within a secure
                    environment.
                  </span>
                </div>

                <h3 className="title">Choices and Obligations</h3>
                <div className="content">
                  <h5>Data retention</h5>
                  <span>
                    We generally retain your personal data as long as you keep
                    your account open or as needed to provide you Services. This
                    includes data you or others provided to us and data
                    generated or inferred from your use of our Services. Even if
                    you only use our Services when looking for a new job every
                    few years, we will retain your information and keep your
                    profile open, unless you close your account. In some cases
                    we choose to retain certain information (e.g., insights
                    about Services use) in a depersonalized or aggregated form.
                  </span>
                  <span>
                    We retain your personal data even after you have closed your
                    account if reasonably necessary to comply with our legal
                    obligations (including law enforcement requests), meet
                    regulatory requirements, resolve disputes, maintain
                    security, prevent fraud and abuse, or fulfill your request
                    to &quot;unsubscribe&quot; from further messages from us. We
                    will retain de-personalized information after your account
                    has been closed.
                  </span>
                  <span>
                    Information you have shared with will remain visible after
                    you close your account or delete the information from your
                    own profile or mailbox, and we do not control data that
                    other Members have copied out of our Services. Groups
                    content and ratings or review content associated with closed
                    accounts will show an unknown user as the source. Your
                    profile may continue to be displayed in the services of
                    others (e.g., search engine results) until they refresh
                    their cache.
                  </span>
                  <h5>
                    Rights to Access and Control Your Personal Data: For
                    personal data that we have about you, you can:
                  </h5>
                  <span>
                    Delete Data: You can ask us to erase or delete all or some
                    of your personal data (e.g., if it is no longer necessary to
                    provide Services to you).
                  </span>
                  <span>
                    Change or Correct Data: You can edit some of your personal
                    data through your account. You can also ask us to change,
                    update or fix your data in certain cases, particularly if
                    it’s inaccurate.
                  </span>
                  <span>
                    Object to, or Limit or Restrict, Use of Data: You can ask us
                    to stop using all or some of your personal data (e.g., if we
                    have no legal right to keep using it) or to limit our use of
                    it (e.g., if your personal data is inaccurate or unlawfully
                    held).
                  </span>
                  <span>
                    Right to Access and/or Take Your Data: You can ask us for a
                    copy of your personal data and can ask for a copy of
                    personal data you provided in machine readable form.
                  </span>
                  <h5>Cross-Border Data Transfers</h5>
                  <span>
                    We process data both inside and outside of the Nigeria and
                    rely on legally provided mechanisms to lawfully transfer
                    data across borders.  Countries where we process data may
                    have laws which are different from, and potentially not as
                    protective as, the laws of your own country.
                  </span>
                  <h5>Lawful Bases for Processing</h5>
                  <span>
                    We will only collect and process personal data about you
                    where we have lawful bases. Lawful bases include consent
                    (where you have given consent), contract (where processing
                    is necessary for the performance of a contract with you
                    (e.g., to deliver the Services you have requested) and
                    “legitimate interests.” Where we rely on your consent  to
                    process personal data, you have the right to withdraw or
                    decline your consent at any time and where we rely on
                    legitimate interests, you have the right to object.
                  </span>
                </div>

                <h3 className="title">Your Privacy Rights</h3>
                <div className="content">
                  <span>
                    In certain circumstances as provided under the Nigeria Data
                    Protection Regulation or any other applicable law, you may
                    object to the processing of your personal information. To
                    make such a request, please use the contact details provided
                    below. We will consider and act upon any request in
                    accordance with applicable data protection laws.
                  </span>
                </div>
                <span>Please contact us should you have questions, discrepancies or grievances regarding this Privacy
Policy or in relation to the collection, storage, processing, disclosure and transfer of any of your
personal information.</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DetailsPolicy;
