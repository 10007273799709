import React, { useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Toast from '../Validation/Toast';
const API_BASE_URL = process.env.REACT_APP_API;


function DetailsWork() {
    const fileInputRef = React.createRef();
    // const [lname, setLName] = useState("");
    // const [fname, setFName] = useState("");
    // const [email, setEmail] = useState("");
    // const [phone, setPhone] = useState("");
    const [files, setFiles] = useState("");

  
    async function UploadCV(e) {
      e.preventDefault();
      const data = new FormData();
    //   data.set("fname", fname);
    //   data.set("lname", lname);
    //   data.set("email", email);
    //   data.set("phone", phone);
      data.set("file", files[0]);
  
      try {
        const response = await fetch(`${API_BASE_URL}/parse`, {
          method: "POST",
          body: data,
          credentials: "include",
        });
  
      if (response.ok) {
            toast.success("Message sent successfully!", {
                autoClose: 4000
              });
        //   setFName("")
        //   setLName("")
        //   setEmail("")
        //   setPhone("")
          setFiles("")

          fileInputRef.current.value = "";

        }
      } catch (error) {
        console.error("Error uploading cv:", error);
      }
    }

    return (
        <>
            <div className="app-how-it-work-area pt-50 pb-100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="app-how-it-work-content">
                                <h2 className="title">How It works</h2>
    
                                <div className="row">

                                    <div className="col-sm-6">
                                        <div className="how-it-work-box">
                                            <span>1</span>
                                            <h5 className="title">
                                                CV Upload
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="how-it-work-box">
                                            <span>2</span>
                                            <h5 className="title">
                                            Review <br />
                                            by Experts
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="how-it-work-box">
                                            <span>3</span>
                                            <h5 className="title">
                                                Feedback &<br />
                                                Recommendations
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="how-it-work-box">
                                            <span>4</span>
                                            <h5 className="title">
                                                Coaching & <br/>
                                                Support
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="how-it-work-box">
                                {/* <img src={workThumb} alt="" /> */}
                            <div className="contact-form">
                                <h4>Get Free CV Review</h4><br/>
                                <p>Upload your CV, and get notable feedbacks from our cv review experts.</p>
                                <form onSubmit={UploadCV} className="row">
                                {/* <div className="col-md-12">
                                        <input type="text" name="fname" value={fname} required onChange={(e) => setFName(e.target.value)} placeholder="First Name" />
                                            
                                    </div>
                                    <div className="col-md-12">
                                        <input type="text" name="lname" value={lname} required onChange={(e) => setLName(e.target.value)} placeholder="Last Name" />
                                            
                                    </div>
                                    <div className="col-md-12">
                                        <input
                                            type="email"
                                            name="email" value={email}
                                            placeholder="Email Address" required onChange={(e) => setEmail(e.target.value)}
                                        />
                                       
                                           
                                    </div>
                                    <div className="col-md-12">
                                        <input type="number" name="phone" required value={phone} onChange={(e) => setPhone(e.target.value)}  placeholder="Your Phone Number" />
                                      
                                    </div> */}
                                    <div className="col-md-12">
                                        <input type='file' ref={fileInputRef} onChange={(e) => setFiles(e.target.files)} accept=".pdf" required />
                                      
                                    </div>
                                    <div className="col-md-6">
                                        <input type="submit" name="submit" value="Upload CV" />
                                    </div>
                                </form>
                                <Toast/>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DetailsWork;