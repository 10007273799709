import React from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
// import SignupHomeEight from '../HomeEight/SignupHomeEight';
import Footer from '../Home/Footer/Footer';
import Clients from './Clients';
import Drawer from '../Mobile/Drawer';
import HeaderAbout from '../Home/Header';
import HeroAbout from './HeroAbout';
import ServicesAbout from './ServicesAbout';
// import TeamAbout from './TeamAbout';

function AboutUs() {
    const [drawer, drawerAction] = useToggle(false);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HeaderAbout drawer={drawer} action={drawerAction.toggle} />
            <HeroAbout />
            <ServicesAbout />
            {/* <TeamAbout /> */}
            <Clients />
            {/* <SignupHomeEight /> */}
            <Footer className="app-footer-about-area" />
            <BackToTop />
        </>
    );
}

export default AboutUs;
