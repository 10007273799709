import React from 'react';
import thumb from '../../assets/images/services.jpg';

function DetailsService({ className }) {
    return (
        <>
        <section className="app-service-details-area pt-100 pb-100" style={{backgroundColor: '#f5f5f5'}}>
                <div className="container">
                    <div className="row">

                    <div className="col-lg-8">
                            <div className="service-details-content">
                                <div className="thumb">
                                    <img src={thumb} alt="" />
                                </div>
                                {/* <div className="content">
                                    <h3 className="title">What we're Building</h3>
                                    <p>
                                    The Blakskill platform is an intuitive platform that transforms the recruitment process into a streamlined and efficient experience. 
                                    With its user-friendly design and powerful features, the platform offers the following services:
                                    </p>

                                   
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
        </section>
        
            <div className={`app-services-2-area pb-100 ${className}`} id="service" style={{backgroundColor: '#f5f5f5'}}>
                <div className="container">
                    <div className="row align-items-end">
                        <div className="col-lg-10 col-md-8">
                            <div className="app-section-title">
                                <h3 className="app-title">Blakskill: Your One-Stop Talent Solutions Provider</h3>
                                <p>
                                    At Blakskill limited,  we understand that talent is the backbone of any successful organization. Finding, nurturing, and retaining top 
                                    talent is crucial for businesses to thrive in today's competitive landscape. As a cutting-edge platform specializing in talent 
                                    identification, acquisition, incubation, and verification, we offer a comprehensive range of services designed to meet all your talent 
                                    needs. Whether you're an employer seeking exceptional individuals or a professional looking to enhance your career prospects, we have 
                                    the solutions tailored just for you.
                                </p>
                            </div>
                            <h3 className="title pt-50 pb-30">Our Services</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="app-single-service-2 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-bolt" />
                                </div>
                                <h4 className="title">Talent Identification</h4>
                                <p>
                                Identifying the right talent is the first step towards building a successful team. Our experts employ advanced methodologies, data-driven 
                                insights, and extensive networks to discover individuals with the skills, expertise, and cultural fit that align with your organization's 
                                goals. We conduct rigorous assessments and screening processes to ensure we identify the cream of the crop for your consideration.
                                </p>

                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="app-single-service-2 item-2 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-home" />
                                </div>
                                <h4 className="title">Talent Development</h4>
                                <p>
                                Nurturing and developing talent is essential for long-term organizational success. Our talent incubation programs provide individuals with 
                                the tools, resources, and guidance they need to reach their full potential. Through tailored training programs, mentoring, and ongoing 
                                support, we help shape the skills and capabilities of your workforce, ensuring they remain motivated, engaged, and ready to tackle new 
                                challenges.
                                </p>

                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="app-single-service-2 item-3 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-link" />
                                </div>
                                <h4 className="title">Talent Verification</h4>
                                <p>
                                Ensuring the authenticity and reliability of talent is vital for maintaining a trustworthy and secure work environment. Our talent 
                                verification services encompass comprehensive background checks, employment verification, educational credential verification, reference 
                                checks, and more. We meticulously verify the information provided by candidates, giving you peace of mind and safeguarding your 
                                organization's reputation.
                                </p>

                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="app-single-service-2 item-4 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-user" />
                                </div>
                                <h4 className="title">Talent Acquisition</h4>
                                <p>
                                Securing the services of top talent is a challenging endeavor. Our talent acquisition specialists streamline the hiring process, saving you 
                                time and effort. We employ a multi-faceted approach, utilizing various sourcing channels, proactive headhunting, and personalized strategies
                                 to identify and attract the most qualified candidates. Our aim is to find the perfect match for your organization, allowing you to build a 
                                 high-performing team.
                                </p>

                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="app-single-service-2 item-5 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-cog" />
                                </div>
                                <h4 className="title">Talent Assessment</h4>
                                <p>
                                Assessing the skills, capabilities, and potential of individuals is crucial for making informed talent decisions. Our talent assessments 
                                provide objective and comprehensive evaluations of candidates and employees, helping you gain deeper insights into their strengths, 
                                weaknesses, and suitability for specific roles. Our assessments are designed to identify the right fit for your organization and enable
                                 you to make data-driven talent decisions.
                                </p>

                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="app-single-service-2 item-6 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-users" />
                                </div>
                                <h4 className="title">Career Advisory & Coaching</h4>
                                <p>
                                For professionals seeking to advance their careers, our career advisory and coaching services provide invaluable guidance and support. Our 
                                experienced career coaches offer personalized advice, helping you identify and capitalize on your strengths, navigate career transitions, 
                                and develop strategies to achieve your professional goals. We empower you to make informed decisions and maximize your career potential.
                                </p>

                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="app-single-service-2 item-2 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-book" />
                                </div>
                                <h4 className="title">CV Review</h4>
                                <p>
                                Our CV review service transforms your CV into a powerful marketing tool tailored to your target industry or role. Maximize your chances of securing interviews and job offers with our professionally written CV revamp service
                                </p>

                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="app-single-service-2 item-4 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-book" />
                                </div>
                                <h4 className="title">CV Revamp</h4>
                                <p>
                                Our CV revamp service transforms your CV into a powerful marketing tool that highlights your strengths, tailored to your target industry 
                                or role. Get a professionally written CV that maximizes your chances of securing interviews and job offers.
                                </p>

                            </div>
                        </div>
                        {/* <div className="col-lg-4 col-md-6">
                            <div
                                className="app-single-service-2 item-6 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-lock" />
                                </div>
                                <h4 className="title">Fast and intuitive</h4>
                                <p>
                                    Oxford posh bevvy give us a bell gutted mate spend a penny
                                    quaint cockup plastered.
                                </p>

                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    );
}

export default DetailsService;
