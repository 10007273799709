import React, { useState, useEffect } from 'react'
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Footer from '../Home/Footer/Footer';
import Drawer from '../Mobile/Drawer';
import Header from '../Home/Header';
import 'react-quill/dist/quill.snow.css';
import Editor from "./Editor";
import {Navigate, useParams} from "react-router-dom";
const API_BASE_URL = process.env.REACT_APP_API;

const UpdateBlog = () => {
    const {id} = useParams();
    const [title,setTitle] = useState('');
    const [content,setContent] = useState('');
    const [category, setCategory] = useState('');
    const [files, setFiles] = useState('');
    const [redirect, setRedirect] = useState(false);
    const [drawer, drawerAction] = useToggle(false);

    useEffect(() => {
        fetch(`${API_BASE_URL}/post/`+id)
          .then(response => {
            response.json().then(postInfo => {
              setTitle(postInfo.title);
              setContent(postInfo.content);
            });
          });
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

const submitHandler = async(ev) => {

    ev.preventDefault();
    const data = new FormData();
    data.set('title', title);
    data.set('category', category)
    data.set('content', content);
    data.set('id', id);
    if (files?.[0]) {
      data.set('file', files?.[0]);
    }
    const response = await fetch(`${API_BASE_URL}/post/`, {
      method: 'PUT',
      body: data,
      credentials: 'include',
    });
    if (response.ok) {
      setRedirect(true);
    }
  }

  if (redirect) {
    return <Navigate to={'/post/'+id} />
  }

  return (
    <>
    <Drawer drawer={drawer} action={drawerAction.toggle} />
    <Header action={drawerAction.toggle} />
    <div className="app-page-title-area app-page-service-title-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="app-page-title-item">
                                <h3 className="title" style={{ color: '#0C0C0C'}}>
                                    Update Blog
                                </h3>
                                {/* <span style={{ color: '#0C0C0C'}}>Blakskill</span> */}
                                <div className="thumb">
                                    {/* <img src={thumb} alt="" /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    <section className="contact-section">
        <div className="container">
            <div className="row">
            
                <div className="col-md-12">
                    <div className="contact-form">
                        <form onSubmit={submitHandler} className="row">
                            <div className="col-md-12">
                                <input type="text" name="title" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Title" required />
                                       
                            </div>
                            <div className="col-md-12">
                                <input type="text" name="category" value={category} onChange={(e) => setCategory(e.target.value)} placeholder="Category" required />
                                       
                            </div>
                            <div className="col-md-12">
                            <input type="file"
             onChange={ev => setFiles(ev.target.files)} required/>
                                       
                            </div>
                            <div className="col-md-12">
                            <Editor value={content} onChange={setContent} />
                                </div>
 
                            <div className="col-md-6 mt-30">
                                <input type="submit" name="submit" value="Update" />
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    </section>
    <Footer />
<BackToTop />
</>
  )
}

export default UpdateBlog
