import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import HeroDot from '../../assets/images/hero-dot.png';
import heroThumb from '../../assets/images/success.png';
import shape from '../../assets/images/shape/shape-10.png';
import Header from '../Home/Header';
import useToggle from '../../Hooks/useToggle';
import StickyMenu from '../../lib/StickyMenu';
import Drawer from '../Mobile/Drawer';

const Success = () => {
    useEffect(() => {
        StickyMenu();
    });
    const [drawer, drawerAction] = useToggle(false);
  return (
    <>
       <Drawer drawer={drawer} action={drawerAction.toggle} />
        <Header action={drawerAction.toggle} />
    <section className="app-hero-area appie-hero-4-area">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-5">
                    <div className="app-hero-content app-hero-content-4">
                        
                        <h1 className="app-title">Your form has been submitted.</h1>
                        <p>
                            Please check your email for further details.
                        </p>
                        <Link className="main-btn" to="/academy">
                            Go Back
                        </Link>
                    </div>
                </div>
                <div className="col-lg-7">
                    <div className="app-hero-thumb app-hero-thumb-4">
                        <img src={heroThumb} alt="" />
                        <div className="hero-dot">
                            <img src={HeroDot} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="hero-shape-1">
            <img src={shape} alt="" />
        </div>
    </section>
</>
  )
}

export default Success
