/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from './Auth/UserContext';
import {Navigate} from "react-router-dom";
const API_BASE_URL = process.env.REACT_APP_API;


function Navigation() {
  const [redirect, setRedirect] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const {setUserInfo,userInfo} = useContext(UserContext);
    useEffect(() => {
      fetch(`${API_BASE_URL}/profile`, {
        credentials: 'include',
      }).then(response => {
        response.json().then(userInfo => {
          setUserInfo(userInfo);
        });
      }).catch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
    function logout() {
      fetch('/logout', {
        credentials: 'include',
        method: 'POST',
      });
      setUserInfo(null);
      setRedirect(true);
    }

    if (redirect) {
      return <Navigate to={'/'} />
    }

  
    const username = userInfo?.username;

    return (
        <>
{!username && (
                <ul>
                            <li>
                                <a href="https://blakskill.com/about">About</a>
                            </li>
                            <li>
                            <a href="https://blakskill.com/services">Services</a>
                            </li>
                            <li>
                                <Link to="/blog">Blog</Link>
                            </li>
                            <li>
                            <a href="https://blakskill.com/cv-review">CV Review</a>
                            </li>
                            <li>
                            <a href="https://blakskill.com/bses">BSES</a>
                            </li>
                            <li>
                                <Link to="/cv-review">CV Revamp</Link>
                            </li>
                            {/* <li>
                                <Link to="/privacy">Privacy policy</Link>
                            </li>
                            <li>
                                <Link to="/terms">Terms of Use</Link>
                            </li> */}

                    {/* <li>
                        <Link to="/support">Support</Link>
                    </li> */}
                </ul>
)}
        {username && (
          <ul>
        <li>
            <Link to="/blog">Blog</Link>
        </li>
          <li><Link to="/blog-create">Create new post</Link></li>
            <li style={{ cursor: 'pointer'}}><a onClick={logout}>Logout ({username})</a></li>
          </ul>
        )}

        </>
    );
}

export default Navigation;