import React from 'react';
// import thumb from '../../assets/images/support.png';

const HeroContact = ({ title, breadcrumb = [] }) => {
    return (
        <>
            <div className="app-page-title-area app-page-service-title-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="app-page-title-item">
                                <h3 className="title" style={{ color: '#0C0C0C'}}>
                                    Support
                                </h3>
                                {/* <span style={{ color: '#0C0C0C'}}>Blakskill</span> */}
                                <div className="thumb pt-100">
                                    {/* <img src={thumb} alt="" /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HeroContact;
