import React, { useState } from 'react';
import { Navigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import shape13 from '../../assets/images/shape/shape-13.png';
import shape14 from '../../assets/images/shape/shape-14.png';
import shape15 from '../../assets/images/shape/shape-15.png';;
const API_BASE_URL = process.env.REACT_APP_API;


function SbessForm() {
    const fileInputRef = React.createRef();
    const [fname, setFName] = useState("");
    const [lname, setLName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [yog, setYog] = useState("");
    const [qual, setQual] = useState("");
    const [grade, setGrade] = useState("");
    const [social, setSocial] = useState("");
    const [files, setFiles] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [redirect, setRedirect] = useState(false);

  
    async function UploadBses(e) {
      e.preventDefault();
      const data = new FormData();
      data.set("fname", fname);
      data.set("lname", lname);
      data.set("email", email);
      data.set("phone", phone);
      data.set("yog", yog);
      data.set("qual", qual);
      data.set("grade", grade);
      data.set("social", social);
      data.set("file", files[0]);
  
      try {
        const response = await fetch(`${API_BASE_URL}/talent`, {
          method: "POST",
          body: data,
          credentials: "include",
        });
  
        if (response.ok) {
            // toast.success("Message sent successfully!", {
            //     autoClose: 4000
            //   });
            // setShowModal(true);
            // setTimeout(() => {
            //   setShowModal(false);
            // }, 4000);
          setFName("")
          setLName("")
          setEmail("")
          setPhone("")
          setQual("")
          setYog("")
          setGrade("")
          setFiles("")
          setSocial("")

          fileInputRef.current.value = "";
          setRedirect(true);

        }

        if (response.status ===  500) {
            // toast.success("Message sent successfully!", {
            //     autoClose: 4000
            //   });
            setShowErrorModal(true);
            setTimeout(() => {
              setShowErrorModal(false);
            }, 8000);

        }
      } catch (error) {
        console.error("Error uploading your form:", error);
      }
    }

    if (redirect) {
        return <Navigate to='/success' />
      }

    return (
        <>
            <section className="app-features-area-2 app-features-area-5 pt-90 pb-100" id="features">
                <div className="container">
                <div className="row mt-30 align-items-center">
                    <div className="col-lg-6">
                    <div className="col-lg-12">
                            <div className="app-section-title text-center pb-100">
                                <h3 className="app-title">
                                    <i>What to Expect</i>
                                </h3>

                            </div>
                        </div>
                            <div className="app-features-boxes ">
                                <div className="app-features-box-item app-features-box-5-item">
                                    <h4 className="title">Cutting-Edge Curriculum</h4>
                                    <p>Our programme is designed by industry experts, delivering the latest insights and practical knowledge that employers seek.</p>
                                </div>
                                <div className="app-features-box-item item-2 app-features-box-5-item">
                                    <h4 className="title">Professional Networking</h4>
                                    <p>Opportunities to meet industry professionals and gain insights into various professions.</p>
                                </div>
                                <div className="app-features-box-item item-3 app-features-box-5-item">
                                    <h4 className="title">Expert Guidance</h4>
                                    <p>Benefit from the wisdom of seasoned professionals and mentors who are dedicated to your success.</p>
                                </div>
                                <div className="app-features-box-item item-2 app-features-box-5-item">
                                    <h4 className="title">Accelerate Your Career</h4>
                                  <p>Our programme will fast-track your career progression, putting you on the path to leadership roles.</p>
                                </div>
                                <div className="app-features-box-item item-3 app-features-box-5-item">
                                    <h4 className="title">Secure Opportunities</h4>
                                    <p>Access exclusive job opportunities and internships through our extensive network of industry partners.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="how-it-work-box wow animated fadeInRight" data-wow-duration="2000ms"
                                data-wow-delay="200ms">
                                {/* <img src={workThumb} alt="" /> */}
                            <div className="contact-form">
                                <h4>Take the First Step towards Success!</h4><br/>
                                <p>Your future starts here. Don't miss out on this opportunity to transform your career.</p>
                                <form onSubmit={UploadBses} className="row">
                                    <div className="col-md-12">
                                        <input type="text" name="fname" value={fname} required onChange={(e) => setFName(e.target.value)} placeholder="First Name" />
                                            
                                    </div>
                                    <div className="col-md-12">
                                        <input type="text" name="lname" value={lname} required onChange={(e) => setLName(e.target.value)} placeholder="Last Name" />
                                            
                                    </div>
                                    <div className="col-md-12">
                                        <input
                                            type="email"
                                            name="email" value={email}
                                            placeholder="Email Address" required onChange={(e) => setEmail(e.target.value)}
                                        />
                                       
                                           
                                    </div>
                                    <div className="col-md-12">
                                        <input type="number" name="phone" required value={phone} onChange={(e) => setPhone(e.target.value)}  placeholder="Your Phone Number" />
                                      
                                    </div>
                                    <div className="col-md-12">
                                        <input
                                            type="text"
                                            name="yog" value={yog}
                                            placeholder="Year of Graduation (2022)" required onChange={(e) => setYog(e.target.value)}
                                        />
                                        </div> 
                                        {/* <div className="col-md-12">
                                        <input
                                            type="text"
                                            name="qual" value={qual}
                                            placeholder="Tertiary Qualification i.e B.Sc, HND" required onChange={(e) => setQual(e.target.value)}
                                        />
                                        </div> */}
                                        <div className='col-md-12'>
                                            <select name="qual" required value={qual} onChange={(e) => setQual(e.target.value)}>
                                                <option value="" disabled>Select Highest Educational Qualification</option>
                                                <option value="post-graduate">Post Graduate</option>
                                                <option value="degree">Degree</option>
                                                <option value="hnd">HND</option>
                                                <option value="nce">NCE</option>
                                                <option value="ond">OND</option>
                                                <option value="others">Others</option>
                                            </select>
                                        </div>
                                        <div className='col-md-12'>
                                            <select name="qual" required value={grade} onChange={(e) => setGrade(e.target.value)}>
                                                <option value="" disabled>Select Educational Grade</option>
                                                <option value="first class">First Class</option>
                                                <option value="second class upper">Second Class Upper</option>
                                                <option value="second class lower">Second Class Lower</option>
                                                <option value="distinction">Distinction</option>
                                                <option value="upper credit">Upper Credit</option>
                                                <option value="lower credit">Lower Credit</option>
                                                <option value="others">Others</option>
                                            </select>
                                        </div>
                                        <div className='col-md-12'>
                                            <select name="social" required value={social} onChange={(e) => setSocial(e.target.value)}>
                                                <option value="" disabled>How did you learn about BSES?</option>
                                                <option value="linkedin">Linkedin</option>
                                                <option value="slack">Slack</option>
                                                <option value="telegram">Telegram</option>
                                                <option value="facebook">Facebook</option>
                                                <option value="google ads">Google Ads</option>
                                                <option value="twitter">Twitter</option>
                                                <option value="instagram">Instagram</option>
                                                <option value="others">Others</option>
                                            </select>
                                        </div>
                                    <div className="col-md-12">
                                    <label htmlFor="cvUpload">Upload your CV in PDF Format!</label>
                                        <input type='file' ref={fileInputRef} onChange={(e) => setFiles(e.target.files)} accept=".pdf" required />
                                    
                                    </div>
                                    <div className="col-md-6">
                                        <input type="submit" name="submit" value="Submit" />
                                    </div>
                                </form>
                                {/* <Toast/> */}
                                {showModal && (
        <div className="col-md-6" style={{ padding: '10px', background: '#591EA5', color: '#fff', margin: '30px', width: 'auto', borderRadius: '7px'}}>
          Message sent successfully!
        </div>
      )}
                                     {showErrorModal && (
        <div className="col-md-6" style={{ padding: '10px', background: '#FFA500', color: '#fff', margin: '30px', width: 'auto', borderRadius: '7px'}}>
          You have submitted your form already!
        </div>
      )}
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="features-shape-1">
                    <img src={shape13} alt="" />
                </div>
                <div className="features-shape-2">
                    <img src={shape14} alt="" />
                </div>
                <div className="features-shape-3">
                    <img src={shape15} alt="" />
                </div>
            </section>
        </>
    );
}

export default SbessForm;