import React, { useEffect } from 'react';
import { Link } from 'react-router-dom'
import logo from '../../assets/images/Blakskill.png';
import StickyMenu from '../../lib/StickyMenu';
import Navigation from '../Navigation';

const Header = ({
    lang,
    darkEnable = false,
    action,
    langEnabled = false,
    changeMode,
    changeModeLan,
    dark,
    className,
}) => {
    useEffect(() => {
        StickyMenu();
    });
    return (
        <header className={`app-header-area app-sticky ${className || ''}`}>
            <div className="container">
                <div className="header-nav-box">
                    <div className="row align-items-center">
                        <div className="col-lg-2 col-md-4 col-sm-5 col-6 order-1 order-sm-1">
                            <div className="app-logo-box">
                                    <Link to="/">
                                        <img src={logo} alt="lakskill_logo" /> 
                                        {/* <span className="text-center" style={{ color: '#0c0c0c', fontSize: '24px', fontWeight: '500'}}>Blakskill</span> */}
                                    </Link>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-1 col-sm-1 order-3 order-sm-2">
                            <div className="app-header-main-menu">
                                <Navigation />
                            </div>
                        </div>
                        {/* <div className="col-lg-5  col-md-7 col-sm-6 col-6 order-2 order-sm-3">
                            <div className="app-btn-box text-right">
                                <a className="main-btn" href="https://apps.apple.com/us/app/blakskill/id6444443898">
                                <i className="fab fa-apple" /> Download for iOS
                                </a>
                                <a className="main-btn ml-30" href="https://play.google.com/store/apps/details?id=com.blakskill.android">
                                <i className="fab fa-google-play" /> Download for
                                            Android
                                </a>
                                <div
                                    onClick={(e) => action(e)}
                                    className="toggle-btn ml-30 canvas_open d-lg-none d-block"
                                >
                                    <i className="fa fa-bars" />
                                </div>
                            </div>
                        </div> */}
                        <div className="col-lg-5  col-md-7 col-sm-6 col-6 order-2 order-sm-3">
                            <div className="app-btn-box text-right">
                                <a className="main-btn" href="https://app.blakskill.com/signin">
                                 Find Talents
                                </a>
                                <a className="main-btn ml-30" href="https://app.blakskill.com/talent/signin">
                               Apply as a Talent
                                </a>
                                <div
                                    onClick={(e) => action(e)}
                                    className="toggle-btn ml-30 canvas_open d-lg-none d-block"
                                >
                                    <i className="fa fa-bars" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;
