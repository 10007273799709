import React, { useState, useEffect } from 'react';
import Blog from './Blog';
const API_BASE_URL = process.env.REACT_APP_API;


function Blogs() {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchPosts = async () => {
          try {
            const response = await fetch(`${API_BASE_URL}/posts`);
            const data = await response.json();
            const posts = data.posts;
            setPosts(posts);
            setLoading(false);
          } catch (error) {
            console.error('Error:', error);
            setLoading(false);
          }
        };
    
        fetchPosts();
      }, []);

      if (loading) {
        return <div>Loading...</div>;
      }
      
    return (
        <>
            <div className="row">
                
            {
        posts.length > 0 ? posts.map(post => (
          <Blog key={post._id} {...post} />
        )) : "No posts"
      }

                {/* <div className="col-lg-6">
                    <div className="post-item-1">
                        <img src={blogImg1} alt="" />
                        <div className="b-post-details">
                            <div className="bp-meta">
                            <Link to="/blog/exploring-role-incubation-nurturing-emerging-talent">
                                    <i className="fal fa-clock"></i>March 22, 2023
                                </Link>
                            </div>
                            <h3>
                                <Link to="/blog/exploring-role-incubation-nurturing-emerging-talent">
                                Exploring the Role of Incubation in Nurturing Emerging Talent
                                </Link>
                            </h3>
                            <Link to="/blog/exploring-role-incubation-nurturing-emerging-talent">
                                Read More<i className="fal fa-arrow-right"></i>
                            </Link>
                        </div>
                    </div>
                </div> */}
            </div>

            {/* <div className="row">
                <div className="col-lg-12">
                    <div className="bisylms-pagination">
                        <span className="current">01</span> */}
                        {/* <a href="#">02</a> */}
                        {/* <a className="next" href="#">
                            next<i className="fal fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div> */}
        </>
    );
}

export default Blogs;
