import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/Blakskill.png';

function Drawer({ drawer, action, lang }) {
//     const [ setSize] = useState('0px');
//     const [setItem] = useState('home');
//     const handler = (e, value) => {
//         // e.preventDefault();
//         const getItems = document.querySelectorAll(`#${value} li`).length;
//         if (getItems > 0) {
//             setSize(`${43 * getItems}px`);
//             setItem(value);
//         }
//     };
    return (
        <>
                    <div
                        onClick={(e) => action(e)}
                        className={`off_canvars_overlay ${drawer ? 'active' : ''}`}
                    ></div>
                    <div className="offcanvas_menu">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div
                                        className={`offcanvas_menu_wrapper ${
                                            drawer ? 'active' : ''
                                        }`}
                                    >
                                        <div className="canvas_close">
                                            <Link to="#" onClick={(e) => action(e)}>
                                                <i className="fa fa-times"></i>
                                            </Link>
                                        </div>
                                        <div className="offcanvas-brand text-center mb-40">
                                            <img src={logo} alt="" />
                                        </div>
                                        <div id="menu" className="text-left ">
                                            <ul className="offcanvas_main_menu">
                            <li>
                                <Link to="/about">About</Link>
                            </li>
                            <li>
                                <Link to="/services">Services</Link>
                            </li>
                            <li>
                                <Link to="/cv-review">CV Review</Link>
                            </li>
                            <li>
                                <Link to="/academy">BSES</Link>
                            </li>
                            <li>
                                <Link to="/blog">Blog</Link>
                            </li>
                                            {/* <li>
                                <Link to="/privacy-policy">Privacy policy</Link>
                            </li> */}
                            {/* <li>
                                <Link to="/terms">Terms of Use</Link>
                            </li> */}
                    <li>
                        <Link to="/support">Support</Link>
                    </li>
                                            {/* <li>
                                            <a href="https://apps.apple.com/us/app/blakskill/id6444443898" className="item-2">
                                                <i className="fab fa-apple" /> Download for iOS
                                            </a>
                                        </li>
                                        <li>
                                            <a className="item-2" href="https://play.google.com/store/apps/details?id=com.blakskill.android">
                                                <i className="fab fa-google-play" /> Download for
                                                Android
                                            </a>
                                        </li> */}
                                        <li>
                                            <a href="https://app.blakskill.com/signin" className="item-2">
                                                 Find Talents
                                            </a>
                                            <a href="https://app.blakskill.com/talent/signin" className="item-2">
                                            Apply as a Talent
                                            </a>
                                        </li>
                                                {/* <li
                                                    onClick={(e) => handler(e, 'home')}
                                                    id="home"
                                                    className="menu-item-has-children active"
                                                >
                                                   
                                                    <Link to="#">Home</Link>

                                                </li>
                                                <li
                                                    onClick={(e) => handler(e, 'service')}
                                                    id="service"
                                                    className="menu-item-has-children active"
                                                >
                                                    <Link to="/service">Service</Link>
                                                </li>
                                                <li
                                                    onClick={(e) => handler(e, 'pages')}
                                                    id="pages"
                                                    className="menu-item-has-children active"
                                                >
                                                   
                                                    <Link to="#">Pages</Link>
                                                </li>
                                                <li
                                                    onClick={(e) => handler(e, 'news')}
                                                    id="news"
                                                    className="menu-item-has-children active"
                                                >
                                                   
                                                    <Link to="#">News</Link>
                                                </li>
                                                <li
                                                    onClick={(e) => handler(e, 'contact')}
                                                    id="contact"
                                                    className="menu-item-has-children active"
                                                >
                                                    <Link to="/contact">Contact</Link>
                                                </li> */}
                                            </ul>
                                        </div>
                                        <div className="offcanvas-social">
                                            <ul className="text-center">
                                                <li>
                                                    <a href="https://web.facebook.com/Blakskill/" target="blank">
                                                        <i className="fab fa-facebook-f" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://twitter.com/Blakskill1" target="blank">
                                                        <i className="fab fa-twitter" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://www.instagram.com/blakskill1/" target="blank">
                                                        <i className="fab fa-instagram" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://www.linkedin.com/company/blakskill-limited/about/" target="blank">
                                                        <i className="fab fa-linkedin-in" />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="footer-widget-info">
                                            <ul>
                                                <li>
                                                <a href="mailto:support@blakskill.com">  
                                                        <i className="fal fa-envelope"></i>
                                                        support@blakskill.com
                                                    </a>
                                                </li>
                                                <li>
                                                
                                                    <a href="tel:+2349159999323">  <i className="fal fa-phone"></i> +(234) 915 9999 323 </a>
                                                  
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        <i className="fal fa-map-marker-alt"></i>
                                                        5 Idowu Taylor Street, Victoria Island, Lagos.
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
        </>
    );
}

export default Drawer;
