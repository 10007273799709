import React from 'react';
import sparkwest from '../../assets/images/sparkwest.png';
import CIPM from '../../assets/images/cipm.png';
import Qshelter from '../../assets/images/Qshelter.png';
import BudChat from '../../assets/images/BudChat.webp';
import BudPay from '../../assets/images/BudPay.png';
import Neca from '../../assets/images/neca.png';
import LSW from '../../assets/images/LSW.png';
import Rentease from '../../assets/images/rentease.png';
import conglex from '../../assets/images/conglex.svg';
import Wtes from '../../assets/images/wtes.jpeg';
import sponserShape from '../../assets/images/sponser-shape.png';

function Clients({ className }) {
    return (
        <>
            <section className={`app-sponser-area pb-100 pt-100 ${className}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="app-section-title text-center">
                                <h3 className="app-title">
                                    Our Partners
                                </h3>
                                {/* <p>Join over 40,000 businesses worldwide.</p> */}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="app-sponser-box d-flex justify-content-center">
                                <div className="sponser-item">
                                    <img src={sparkwest} alt="Sparkwest" />
                                </div>
                                <div className="sponser-item">
                                    <img src={Qshelter} alt="Qshelter" />
                                </div>
                                <div className="sponser-item">
                                    <img src={BudChat} alt="Budchat" />
                                </div>
                                <div className="sponser-item">
                                    <img src={BudPay} alt="Budpay" />
                                </div>
                                <div className="sponser-item">
                                    <img src={Neca} alt="Neca" />
                                </div>
                                <div className="sponser-item">
                                    <img src={LSW} alt="Lagos Startup week" />
                                </div>
                            </div>
                            <div className="app-sponser-box item-2 d-flex justify-content-center">
                                <div className="sponser-item">
                                    <img src={Rentease} alt="Rentease" />
                                </div>
                                <div className="sponser-item">
                                    <img src={conglex} alt="Conglex" />
                                </div>
                                <div className="sponser-item">
                                    <img src={Wtes} alt="Wtes" />
                                </div>
                                <div className="sponser-item">
                                    <img src={CIPM} alt="CIPM" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sponser-shape">
                    <img src={sponserShape} alt="" />
                </div>
            </section>
        </>
    );
}

export default Clients;
