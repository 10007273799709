import React from 'react';


const TermsHero = () => {
    return (
        <>
            <div className="app-page-title-area app-page-service-title-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                        <div className="app-page-title-item">
                                
                                <h3 className="title" style={{ color: '#0C0C0C'}}>
                                    Terms and Conditions of Use
                                </h3>
       
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TermsHero;
