import React, { useState, useEffect } from 'react';
import { SendEmail } from '../API';
import InlineError from '../Validation/InlineError';
import { validateEmail, validateFullName, validateInquiry, validateMessage } from '../Validation/Validate'
import { toast } from 'react-toastify';
import Toast from '../Validation/Toast';
import 'react-toastify/dist/ReactToastify.css';

function Forms() {

    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [inquiry, setInquiry] = useState('');
    const [fullNameError, setFullNameError] = useState();
    const [emailError, setEmailError] = useState();
    const [messageError, setMessageError] = useState();
    const [inquiryError, setInquiryError] = useState();
    const [buttonLoading, setButtonLoading] = useState(false);
    const [send, setSend] = useState();

    useEffect(() => {

        // *********** VALIDATION **********
        validateFullName({ fullName, setFullNameError });
        validateEmail({ email, setEmailError });
        validateInquiry({ inquiry, setInquiryError});
        validateMessage({ message, setMessageError });
    
        // ***********
        if (send) {
          toast.success(send.msg);
          setFullName("")
          setEmail("")
          setInquiry("")
          setMessage("")
          setSend()
        }
      }, [fullName, email, inquiry, message, send ]);
    
      const submitHandler = (e) => {
        e.preventDefault();
        setButtonLoading(true);
        if (!fullNameError & !emailError & !inquiryError  & !messageError) {
          SendEmail({ fullName, email, inquiry, message, setSend }).then(
            () => {
              setButtonLoading(false);
            }
          );
        }
      };

    return (
        <>
            <section className="contact-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="contact--info-area">
                                <h3>Get in touch</h3>
                                <p>Looking for help? Fill the form and start a new adventure.</p>
                                {/* <div className="single-info">
                                    <h5>Headquaters</h5>
                                    <p>
                                        <i className="fal fa-home"></i>
                                        744 New York Ave, Brooklyn, Kings,
                                        <br /> New York 10224
                                    </p>
                                </div> */}
                                <div className="single-info">
                                    <h5>Phone</h5>
                                    <p>
                                        <i className="fal fa-phone"></i>
                                        <a href="tel:+2349159999323"> +(234) 915 9999 323 </a> 
                                    </p>
                                </div>
                                <div className="single-info">
                                    <h5>Support</h5>
                                    <p>
                                       <a href="mailto:support@blakskill.com"> <i className="fal fa-envelope"></i>
                                        support@blakskill.com
                                        </a>
                                        <br />
                                       
                                    </p>
                                </div>
                                {/* <div className="ab-social">
                                    <h5>Follow Us</h5>
                                    <a className="fac" href="#">
                                        <i className="fab fa-facebook-f"></i>
                                    </a>
                                    <a className="twi" href="#">
                                        <i className="fab fa-twitter"></i>
                                    </a>
                                    <a className="you" href="#">
                                        <i className="fab fa-youtube"></i>
                                    </a>
                                    <a className="lin" href="#">
                                        <i className="fab fa-linkedin-in"></i>
                                    </a>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="contact-form">
                                <h4>Let’s Connect</h4>
                                <p>Have any questions? We'd love to hear from you.</p>
                                <form onSubmit={submitHandler} className="row">
                                    <div className="col-md-12">
                                        <input type="text" name="fullName" value={fullName} onChange={(e) => setFullName(e.target.value)} placeholder="Full Name" required />
                                                {fullNameError && <InlineError error={fullNameError} />}
                                    </div>
                                    <div className="col-md-12">
                                        <input
                                            type="email"
                                            name="email" value={email}
                                            placeholder="Email Address" required onChange={(e) => setEmail(e.target.value)}
                                        />
                                       
                                            {emailError && <InlineError error={emailError} />}
                                    </div>
                                    <div className="col-md-12">
                                        <input type="text" name="inquiry" required value={inquiry} onChange={(e) => setInquiry(e.target.value)}  placeholder="Your Inquiry" />
                                        {inquiryError && <InlineError error={inquiryError} />}
                                    </div>
                                    <div className="col-md-12">
                                        <textarea
                                            name="message" value={message} required onChange={(e) => setMessage(e.target.value)}
                                            placeholder="I would like to know..."
                                        ></textarea>
                                        
                                    </div>
                                    <div className="col-md-6">
                                        <input type="submit" disabled={buttonLoading && true} name="submit" value="Send Message" />
                                    </div>
                                </form>
                                <Toast/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <div className="bisylms-map">
                <iframe
                    title="map"
                    src="https://maps.google.com/maps?width=720&amp;height=600&amp;hl=en&amp;coord=39.966528,-75.158284&amp;q=1%20Grafton%20Street%2C%20Dublin%2C%20Ireland+(My%20Business%20Name)&amp;ie=UTF8&amp;t=p&amp;z=16&amp;iwloc=B&amp;output=embed"
                ></iframe>
            </div> */}
        </>
    );
}

export default Forms;
