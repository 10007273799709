import React, { useEffect } from 'react';
import useToggle from '../../Hooks/useToggle';
import StickyMenu from '../../lib/StickyMenu';
import BackToTop from '../BackToTop';
import Footer from '../Home/Footer/Footer';
// import ProjectHomeOne from '../HomeOne/ProjectHomeOne';
import Drawer from '../Mobile/Drawer';
import DetailsService from './DetailsService';
import Header from '../Home/Header';
// import Header from '../PrivacyPolicy/HeaderService';
import HeroService from './HeroService';

function Service() {
    useEffect(() => {
        StickyMenu();
    });
    const [drawer, drawerAction] = useToggle(false);
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} />
            <HeroService />
            <DetailsService /> 
            {/* <ProjectHomeOne /> */}
            <Footer />
            <BackToTop />
        </>
    );
}

export default Service;
