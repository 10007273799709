import React from 'react';
import { ToastContainer } from 'react-toastify';

export default function Toast() {
  return (
    <ToastContainer
    icon={false}
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss={false}
      draggable={false}
      pauseOnHover={false}
      style={{color: 'black', fontSize: '18px'}}
    />
  );
}
