import React from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Footer from '../Home/Footer/Footer';
import Drawer from '../Mobile/Drawer';
// import singlePost from '../../assets/images/close-up-colleagues-work.jpg';
import BlogPage from './BlogPage';
// import BlogSideBar from './BlogSideBar';
import Header from '../Home/Header';
import HeroNews from './HeroNews';

function SingleNews() {
    const [drawer, drawerAction] = useToggle(false);
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} />
            <HeroNews
                title="Blog"
                breadcrumb={[
                    { link: '/', title: 'home' },
                    { link: '/news', title: 'Blogs' },
                    { link: '/news/single-news', title: 'Blog' },
                ]}
            />
            <section className="blogpage-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 col-md-8">
                            <BlogPage />
                        </div>

                    </div>
                </div>
            </section>
            <Footer />
            <BackToTop />
        </>
    );
}

export default SingleNews;
