import React from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Footer from '../Home/Footer/Footer';
import Drawer from '../Mobile/Drawer';
import Header from '../Home/Header';
import HeroNews from './HeroNews';
import Forms from './Forms';

function Contact() {
    const [drawer, drawerAction] = useToggle(false);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header drawer={drawer} action={drawerAction.toggle} />
            <HeroNews
                title="Support"
                breadcrumb={[
                    { link: '/', title: 'home' },
                    { link: '/support', title: 'Support' },
                ]}
            />
            <Forms />
            <Footer />
            <BackToTop />
        </>
    );
}

export default Contact;
