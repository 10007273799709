const validateEmail = ({ email, setEmailError }) => {

    const emailRegular =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return email && !email.match(emailRegular)
      ? setEmailError('Email is not valid')
      : setEmailError('');
  };
  

  
  const validateFullName = ({ fullName, setFullNameError }) => {
    return fullName && fullName.length < 5
      ? setFullNameError('Full name is too short')
      : fullName && fullName.length > 60
      ? setFullNameError('Full name is too long')
      : setFullNameError('');
  };

  const validateName = ({ name, setNameError }) => {
    return name && name.length < 5
      ? setNameError('Full name is too short')
      : name && name.length > 60
      ? setNameError('Full name is too long')
      : setNameError('');
  };

  const validateInquiry = ({ inquiry, setInquiryError }) => {
    return inquiry && inquiry.length < 5
      ? setInquiryError('inquiry is too short')
      : inquiry && inquiry.length > 60
      ? setInquiryError('inquiry is too long, please make it brief')
      : setInquiryError('');
  };
  
  const validateMessage = ({ message, setMessageError }) => {
    return message && message.length < 5
      ? setMessageError('Message is too short')
      : message && message.length > 500
      ? setMessageError('Message is too long, please make it brief.')
      : setMessageError('');
  };

  const validateFile = ({ file, setFileError }) => {
    return !file
      ? setFileError('Upoad your cv')
      : setFileError('');
  };

  const validatePhone = ({ phone, setPhoneError }) => {
    return phone && phone.length < 5
      ? setPhoneError('phone is too short')
      : phone && phone.length > 60
      ? setPhoneError('phone is too long, please make it brief')
      : setPhoneError('');
  };
  
  export { validateEmail, validateFullName, validateName, validateInquiry,validateMessage,validatePhone, validateFile };