import React from 'react';
import thumb from '../../assets/images/bses-cover.jpg';

function DetailsService() {
    return (
        <>
            <section className="app-service-details-area pt-100 pb-100" style={{ backgroundColor: '#0C0C0C'}}>
                <div className="container">
                    <div className="row">
                        {/* <div className="col-lg-4">
                            <div className="service-details-sidebar mr-50">
                                <div className="service-category-widget">
                                    <ul> */}
                                        {/* <li>
                                            <i className="fal fa-file"></i>Registration and Subscription
                                        </li> */}
                                        {/* <li>
                                            <i className="fal fa-upload"></i>CV Upload
                                        </li>
                                        <li>
                                            <i className="fal fa-user-friends"></i>Review by Experts
                                        </li>
                                        <li>
                                            <i className="fal fa-bookmark"></i>Feedback
                                        </li>
                                        <li>
                                            <i className="fal fa-globe"></i>Coaching and Support
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div> */}
                        <div className="col-lg-11">
                            <div className="service-details-content">
                            <div className="content" >
                                    <h3 className="title" style={{ color: '#ffffff'}}>Unlock Your Career Potential with BSES</h3>
                                    <p style={{ color: '#ffffff'}}>Welcome to the Blakskill School of Employability Studies (BSES) - Your Gateway to a Thriving Career!</p>
                                    <p style={{ color: '#ffffff'}}>At BSES, we understand that your career aspirations are limitless, and we are here to help you realize 
                                        your full potential. We are excited to introduce you to an educational journey that will transform your career trajectory and open doors to endless opportunities.</p>
                            </div>
                                <div className="thumb">
                                    <img src={thumb} alt="CV Review" />
                                </div>
                                <div className="content">
                                    <h4 className="title" style={{ color: '#ffffff'}}>About BSES</h4>
                                   <p style={{ color: '#ffffff'}}>The Blakskill School of Employability Studies (BSES) is Blakskill Academy’s flagship Learning hub, designed to focus primarily on equipping objectively selected 
                                    university graduates with the critical competencies required to secure, retain, and thrive in gainful employment across the world.</p>
                                    <p style={{ color: '#ffffff'}}>At BSES, we are more than just a programme; we are a life-changing experience designed to equip you with the critical competencies needed to excel in today's dynamic 
                                        job market. Our mission is clear: to empower you, regardless of your background, to secure, retain, and thrive in gainful employment across the world.</p>
                                    <p style={{ color: '#ffffff'}}>By registering for the BSES programme, you are taking the first step towards a brighter future. We will equip you with the skills and knowledge needed to 
                                        succeed, whether you are a recent graduate or looking to enhance your employability.</p>
                                        <p style={{ color: '#ffffff'}}>Our inaugural BSES Cohort 1, a one-month training program, is set to commence in October 2023.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default DetailsService;
