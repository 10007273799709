import React from 'react';


function HeroService() {
    return (
        <>
            <div className="app-page-title-area app-page-service-title-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="app-page-title-item">
                                <span>Fast Development</span>
                                <h3 className="title" style={{ color: '#0C0C0C'}}>
                                    CV Review
                                </h3>
                                {/* <div className="thumb">
                                    <img src={thumb} alt="" />
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HeroService;
