import axios from 'axios';

// *********** Send email
export const SendEmail = async ({
    fullName,
    email,
    inquiry,
    message,
    setSend,
  }) => {
    try {
      const datas = { fullName, email, inquiry, message };
      let res = await axios.post('/send', datas);
      console.log(res)
      if (res) {
        setSend(res.data);
        console.log(res.data)
      }
    } catch (error) {
      // alert(error.response.data.message);
    }
  };